import React from "react"
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import HeroImage from '../components/HeroImage'
import Map from '../components/Map'
import Hours from '../components/Hours'
import SEO from '../components/SEO'

const Covid = ({ data }, props) => {
  return(
    <Layout>
    <SEO
      title="COVID-19 Information"
    />
    <div className={`${props.isDismissed ?  'padding-content-section alert-dismissed' : 'padding-content-section alert-present'}`}>
      <h3 className="responsive-title">Important information regarding COVID-19</h3>
      <div>
        <p>
            We are committed to fighting the spread COVID-19 by:
        </p>
        <ul>
          <li>Meeting/following state, local and regulatory guidelines for sanitation.</li>
          <li>Appointments are staggered to minimize social contact</li>
          <li>Sanitizing/cleaning procedures command 30 mins between each session</li>
          <li>Therapist(s) temperature(s) will be taken at the start of daily shift and should register 99.8 or below</li>
          <li>Therapist(s) must mask before, during and after session</li>
        </ul>
        <p>
             We are asking/requiring clients to:
        </p>
        <ul>
          <li>Submit to a touchless thermometer Temperature check prior to session (clients with a registered temperature of 100.0 or higher
               will be required to reschedule his/her appointment)</li>
          <li>Answer and initial form acknowledging/affirming virus free health</li>
          <li>Wear a face mask while in the office and during massage session</li>
        </ul>
      </div>
      <div>
        <h4 className="responsive-title">Ready to take the next step?</h4>
        <Link to="/contact" aria-label="Request an Appointment"><button className="button button-primary">Request an Appointment</button></Link>
      </div>
    </div>
    <HeroImage heroText="Hours" subtext={<Hours />} cta="" class="hero-image-alt" source='/static/lymphatic-771fe38ee8af92956bf817de79d0754c.jpg'/>
    <div id="location">
      <Map />
    </div>
    </Layout>
  )
}

export default Covid;

export const query = graphql`
query{
  site{
    siteMetadata{
      contact{
        phone
        email
      }
    }
  }
}
`
